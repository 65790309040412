<template>
    <h2>THE ISS: WEB DEVELOPMENT I PROJECT</h2>
    <div class="content">
        <h1>BRIEF</h1><br>
        <p>Design, develop, and publish a web site using recommended design practices. Your web site will contain a home page and at least three (but no more than six) content pages. 
            You are required to create an external style sheet (.css file) that configures text, color, and page layout.</p><br>
        <h1>THOUGHTS</h1><br>
        <p>When thinking about what to do for this project, I knew I wanted to do something space related, so I settled on the ISS. I had an idea of the homepage being a view through the 
            cupola of the ISS and a live feed of the ISS cameras behind it.</p><br>
        <h1>WHAT DID I LEARN?</h1><br>  
        <p>Being a freshman with a basic understanding of HTML and CSS, this project allowed me to use my creative freedoms while teaching me about HTML and CSS in the process. In this project
            I learned about the HTML iframe tag and the CSS z-index property, which I used to create something I still think is pretty cool, even if it's a basic freshman project. I used iframe to 
            put a live feed of the ISS onto the page, and then used the z-index property to move it back a layer so I could overlay the page with the a design of the ISS cupola.</p><br>
        <h1 class="center">SITEMAP AND WIREFRAME</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/iss2.png"></div>
            <div><img src="../assets/iss3.png"></div>
        </div>
        <h1 class="center">FINAL SITE</h1><br>
        <div class="imagecontainer">
            <iframe id="issiframe" src="/iss/index.html" height="576" width="1024"></iframe>
        </div><br><br>
        
        <!--<div class="imagecontainer wrap">
            <div><img src="../assets/iss4.png"></div>
            <div><img src="../assets/iss6.png"></div>
            <div><img src="../assets/iss5.png"></div>
        </div>-->
    </div>
</template>